import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import VideoSection from "../components/videoSection"
import Layout from "../components/Layout"
// import Content, { HTMLContent } from '../components/Content'
// import ReactPlayer from "react-player"
// import IconPlay from "../img/icons/play.svg"

export const MethodsPageTemplate = ({
					title,
					image,
					herovideo,
					html,
					// content,
					// contentComponent,
				}) => {

					return (
						<>
							<VideoSection herovideo={herovideo} heroimage={image} />
							<section className="section">
								<div className="container">
									<div className="columns">
										<div className="column">
											<div
												className="content"
												dangerouslySetInnerHTML={{
													__html: html,
												}}
											/>
										</div>
									</div>
								</div>
							</section>
						</>
					)
				}

MethodsPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	herovideo: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	content: PropTypes.string,
	contentComponent: PropTypes.func,
}

const MethodsPage = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark

	return (
		<Layout>
			<MethodsPageTemplate
				{...frontmatter}
				html={html}
				// contentComponent={HTMLContent}
				// content={frontmatter.html}
			/>
		</Layout>
	)
//   const { markdownRemark: post } = data

//   return (
// 		<Layout>
// 			<MethodsPageTemplate
// 				contentComponent={HTMLContent}
// 				title={post.frontmatter.title}
// 				content={post.html}
// 				herovideo={post.herovideo}
// 			/>
// 		</Layout>
// 	)
}

MethodsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MethodsPage

export const MethodsPageQuery = graphql`
					query MethodsPageTemplate {
						markdownRemark(
							frontmatter: { templateKey: { eq: "methods-page" } }
						) {
							html
							frontmatter {
								title
								image {
									childImageSharp {
										fluid(maxWidth: 1520, quality: 94) {
											...GatsbyImageSharpFluid
										}
									}
									publicURL
								}
								herovideo
							}
						}
					}
				`
